html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'DBOzoneX';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html,
body,
#app,
#app>div {
  height: 100%
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none !important;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
}